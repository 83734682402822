import { RemixBrowser } from "@remix-run/react";
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HTTPBackend from "i18next-http-backend";
import { StrictMode, startTransition } from "react";
import { hydrateRoot } from "react-dom/client";
import { I18nextProvider, initReactI18next } from "react-i18next";
import { getInitialNamespaces } from "remix-i18next";
import { i18n } from "./utils/i18n.ts";
import localForage from "localforage";
if (ENV.MODE === "production" && ENV.SENTRY_DSN) {
	import("./utils/monitoring.client.tsx").then(({ init }) => init());
}

// You can use the configureGlobalCache function to override the libraries default in-memory cache adapter
async function hydrate() {
	await i18next
		.use(initReactI18next) // Tell i18next to use the react-i18next plugin
		.use(LanguageDetector) // Setup a client-side language detector
		.use(HTTPBackend) // Setup your backend
		.init({
			...i18n, // spread the configuration
			// This function detects the namespaces your routes rendered while SSR use
			ns: getInitialNamespaces(),
			backend: {
				loadPath: "/locales/{{lng}}/{{ns}}.json",
				// you can also use a query parameter in production env to force reloading the translations
				// queryStringParams: { v: "version" },
				customHeaders: {
					"cache-control":
						ENV.MODE === "development"
							? "no-cache, no-store, must-revalidate"
							: "no-cache, no-store, must-revalidate",
				},
			},

			detection: {
				// Here only enable htmlTag detection, we'll detect the language only
				// server-side with remix-i18next, by using the `<html lang>` attribute
				// we can communicate to the client the language detected server-side
				order: ["htmlTag"],
				// Because we only use htmlTag, there's no reason to cache the language
				// on the browser, so we disable it
				caches: [],
			},
		});

	startTransition(() => {
		hydrateRoot(
			document,
			<I18nextProvider i18n={i18next}>
				<RemixBrowser />
				{/*<StrictMode>
				</StrictMode>*/}
			</I18nextProvider>,
		);
	});
}

if (window.requestIdleCallback) {
	window.requestIdleCallback(hydrate);
} else {
	// Safari doesn't support requestIdleCallback
	// https://caniuse.com/requestidlecallback
	window.setTimeout(hydrate, 1);
}
